<template>
  <button type="button" class="white-btn" :disabled="disabled">
    <slot />
  </button>
</template>

<script>
export default {
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="sass" scoped>
.white-btn
  background: var(--primary-color-white)
  border-radius: var(--primary-border-radius)
  border: 1px solid rgba(40, 59, 89, 0.3)
  box-shadow: var(--primary-border-shadow-1)
  color: var(--primary-color-dark-blue)
  cursor: pointer
  width: 266px
  height: 100px
  padding: 35px 25px
  font-weight: 600
  font-size: 24px
  line-height: 32px
  display: flex
  align-items: center
  transition: var(--transition-speed)

.white-btn
  @media screen and (max-width: 1199px)
    max-width: 208px
    font-size: 22px
    padding: 35px 30px 35px 22px

.white-btn:hover
  color: var(--btn-color-blue-hover)
  border: 1px solid rgba(48, 83, 240, 0.5)
</style>
