<template>
  <div class="signup-type">
    <SignupOverlay>
      <Heading level="4" class="">
        {{ $t('signup.main_title') }}
      </Heading>
      <div class="signup-type__buttons">
        <ButtonDashboard
          class="signup-type__btn"
          @click.native="goToSignupIndividualPerson"
        >
          <svg-icon name="individual-person" class="control__icons"></svg-icon>
          <span class="control__btn-text">
            {{ $t('signup.type.individual') }}
          </span>
        </ButtonDashboard>
        <ButtonDashboard
          class="signup-type__btn"
          @click.native="goToSignupLegal"
        >
          <svg-icon name="legal-person" class="control__icons"></svg-icon>
          <span class="control__btn-text">
            {{ $t('signup.type.legal') }}
          </span>
        </ButtonDashboard>
      </div>
    </SignupOverlay>
  </div>
</template>

<script>
import SignupOverlay from '@/components/Signup/SignupOverlay.vue';
import Heading from '@/elements/Heading/Heading.vue';
import ButtonDashboard from '@/elements/Buttons/ButtonDashboard.vue';

export default {
  components: {
    SignupOverlay,
    Heading,
    ButtonDashboard,
  },
  data() {
    return {
      signupType: [
        {
          title: 'Фізична особа',
          type: 'individual-person',
        },
        {
          title: 'Юридична особа',
          type: 'legal-person',
        },
      ],
    };
  },
  methods: {
    goToSignupIndividualPerson() {
      this.$router.push({ name: 'SignupIndividualPerson' });
    },
    goToSignupLegal() {
      this.$router.push({ name: 'SignupLegalPerson' });
    },
  },
};
</script>

<style lang="sass" scoped>
.signup-type .signup-overlay:before
  height: 100%

.signup-type__buttons
  display: flex
  align-items: center
  justify-content: center
  gap: 40px

  @media screen and (max-width: 600px)
    flex-direction: column
    gap: 32px

.signup-type__btn
  width: 290px

  @media screen and (max-width: 1199px)
    max-width: 100%

  @media screen and (max-width: 767px)
    padding: 35px 20px
    font-size: 20px

  @media screen and (max-width: 600px)
    width: 260px
    height: 72px
    font-size: 18px
    padding: 20px 30px

.signup-type__btn span
  margin-left: 14px
</style>
